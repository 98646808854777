
import Vue from "vue";
import rules from "@/services/helpers/validation-rules";

export default Vue.extend({
  name: "Experts",

  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({})
    },
    errorMessages: {
      type: Object,
      required: false,
      default: () => ({})
    },
    subdivision: {
      type: Number,
      required: false
    }
  },

  data: () => ({
    rules,
    model: {} as any,
    dialog: false as boolean,
    selects: {
      experts: [] as Array<any>
    } as any,
    mainExpert: 0 as number
  }),

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.model = this.value;
        this.setHandler();
      }
    },
    mainExpert() {
      if (this.model.experts[this.mainExpert]) {
        this.model.order.handler_id = this.model.experts[
            this.mainExpert
            ].user_id;
      }
    }
  },

  computed: {
    totalPercent() {
      return this.model?.experts?.reduce(
          (total: number, { percent }: { percent: number }) => {
            if (Number(percent)) {
              return total + Number(percent);
            }
            return total;
          },
          0
      );
    },
    isValidPercent() {
      return this.totalPercent !== 100;
    },
    expertsPercent() {
      return this.model.experts.reduce(
          (total: number, { percent }: { percent: number }, index: number) => {
            if (Number(percent) && this.mainExpert !== index) {
              return total + Number(percent);
            }
            return total;
          },
          0
      );
    }
  },

  async beforeMount(): Promise<void> {
    await this.loadData();
    this.setHandler();
  },

  methods: {
    setHandler() {
      if (
          this.model.order.handler_id &&
          this.model.experts.some(
              (item: any) => item.user_id === this.model.order.handler_id
          )
      ) {
        this.mainExpert = this.model.experts.findIndex(
            (item: any) => item.user_id === this.model.order.handler_id
        );
      }
    },
    async loadData(): Promise<void> {
      try {
        this.selects.experts = await this.$API.users().getList({
          filter: {
            roles: "subdivision_head,expert,expert_chief,branch_head"
          }
        });
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    checkMainExpert(expertId: number) {
      if (!this.model.order.handler_id || this.model.experts.length === 1) {
        this.model.order.handler_id = expertId;
      }
      this.$forceUpdate();
    },
    addExpert() {
      this.model.experts.push({});
    },
    removeExpert(index: number): void {
      this.model.experts.splice(index, 1);
    }
  }
});
